import { AppBar, Box, Container, CssBaseline, Divider, Drawer, FormControl, IconButton, InputLabel, Link, List, ListItem, ListItemButton, ListItemIcon, ListItemText, MenuItem, Select, ThemeProvider, Toolbar, Typography, createTheme, useMediaQuery, useScrollTrigger, useTheme } from "@mui/material"
import { graphql } from "gatsby";
import { useI18next, useTranslation, Link as ILink } from "gatsby-plugin-react-i18next";
import * as React from "react"
import styled from 'styled-components'
import MenuIcon from '@mui/icons-material/Menu';
import InboxIcon  from '@mui/icons-material/Inbox';
import MailIcon  from '@mui/icons-material/Mail';
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { useState } from "react";
import HomeIcon from '@mui/icons-material/Home';
import LandscapeIcon from '@mui/icons-material/Landscape';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import NewspaperIcon from '@mui/icons-material/Newspaper';

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary">
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const Lang = styled(ILink)`
  width: 100%;
  display: block;
  text-decoration: none;
  color: white;
  font-weight: 500;
  padding: 5px;
`
const BarContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  @media(min-width: 768px) {
    justify-content: center;
    align-content: center;
    justify-items: center;
    align-items: center;
  }
`
const BarContent = styled.div`
  @media(min-width: 768px) {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: space-around;
    align-content: center;
    justify-items: center;
    align-items: center;
    max-width: 950px;
    justify-self: center;
    align-self: center;
  }
`

const SideContainer =  styled.div`
  @media(min-width: 768px) {
    width: 120px
  }
`

const SmallTitle = styled(Typography)`
  width: 270px
`

const FullContainer = styled(Container)`
  padding: 0!important;
  width: 100%;
`
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: "#447db1",
    },
    info: {
      main: "#447db1"
    }
  },
});

const Layout = (props: any) => {
  const {t, i18n} = useTranslation();
  const {languages, originalPath} = useI18next();
  const [openDrawer, toggleDrawer] = useState(false)
  const theme = useTheme();
  const content = React.useRef(null)
  let scroller = content.current?.querySelector("#scroll")
  const [scrollTop, setScrollTop] = useState(scroller ? scroller.scrollTop : 0);
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const hasPageTitle = i18n.exists(props.uri.replace('/', ''))
  const pageTitle = hasPageTitle ? t(props.uri.replace('/', '')) : props.data?.contentfulProject?.title
  const language = props.pageContext.language
  React.useEffect(() => {
    scroller = content.current?.querySelector("#scroll")
    const handleScroll = event => {
      if (!scroller) setScrollTop(window.scrollY);
      else setScrollTop(scroller.scrollTop);
    };
    if (!scroller && window) window.addEventListener('scroll', handleScroll);
    else scroller.addEventListener('scroll', handleScroll);

    return () => {
      if (!scroller && window) window.removeEventListener('scroll', handleScroll);
      else scroller.removeEventListener('scroll', handleScroll);
    };
  }, [content.current, matches]);
  return (
    <ThemeProvider theme={darkTheme}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        <CssBaseline />
        <AppBar position="absolute" color="transparent" elevation={0}>
          <Toolbar>
            <SideContainer>
              <IconButton
                onClick={() => toggleDrawer(true)}
                size="large"
                edge="start"
                aria-label="menu"
                sx={{ mr: 2 }}
              >
                <MenuIcon style={{color: 'white'}} />
              </IconButton>
            </SideContainer>
            { (scrollTop < 20) ?
              <BarContainer>
                <BarContent>
                  { (!pageTitle && matches) &&
                    <SmallTitle variant="h6" color="white" noWrap>
                      {t('numeric_landscape')}
                    </SmallTitle>
                  }
                  { matches &&
                    <AniLink style={{textDecoration: 'none'}} fade duration={1} to={`${language === 'en' ? '/en/' : '/'}`}>
                      <Typography variant="h3" color="white" noWrap>
                        { pageTitle ? pageTitle : "Cervante" }
                      </Typography>
                    </AniLink>
                  }
                  { !matches &&
                    <AniLink style={{textDecoration: 'none'}} fade duration={1} to={`${language === 'en' ? '/en/' : '/'}`}>
                      <Typography variant="h5" color="white" noWrap>
                        { "Cervante" }
                      </Typography>
                    </AniLink>
                  }
                  { (!pageTitle && matches) &&
                    <SmallTitle variant="h6" color="white" noWrap>
                      {t('disgn_concepts')}
                    </SmallTitle>
                  }
                </BarContent>
              </BarContainer> : <BarContainer></BarContainer>
            }
            <SideContainer>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="demo-simple-select-standard-label">Lang</InputLabel>
                <Select
                  style={{color: 'white'}}
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={props.pageContext.language}
                  label="Lang"
                >
                  {languages.map((lng) => (
                    <Lang to={originalPath} language={lng} key={lng} value={lng}>
                      {lng}
                    </Lang>
                  ))}
                </Select>
              </FormControl>
            </SideContainer>
          </Toolbar>
        </AppBar>
        <FullContainer component="main" maxWidth="full" ref={content}>
          {props.children}
        </FullContainer>
        <Drawer
          anchor={'left'}
          open={openDrawer}
          onClose={() => toggleDrawer(false)}
        >
          <Box
            sx={{ width: 250, display: 'flex', backgroundColor: 'primary.dark', flexDirection: 'column', height: '100%'}}
            role="presentation"
            onClick={() => toggleDrawer(false)}
            onKeyDown={() => toggleDrawer(false)}
          >
            <List sx={{flex: 1}}>
              <ListItem disablePadding>
                <AniLink 
                  delay={0.3}
                  fade
                  style={{width: '100%', textDecoration: 'none'}}
                  duration={1} to={`${language === 'en' ? '/en/' : '/'}`}>
                  <ListItemButton>
                    <ListItemIcon>
                      <HomeIcon />
                    </ListItemIcon>
                    <ListItemText sx={{color: 'white', textDecoration: 'none'}} primary={t('home')} />
                  </ListItemButton>
                </AniLink>
              </ListItem>
              <ListItem disablePadding>
                <AniLink 
                  delay={0.3} 
                  fade 
                  duration={1}
                  style={{width: '100%', textDecoration: 'none'}}
                  to={`${language === 'en' ? '/en/' : '/'}projects`}>
                  <ListItemButton>
                    <ListItemIcon>
                      <LandscapeIcon />
                    </ListItemIcon>
                    <ListItemText sx={{color: 'white', textDecoration: 'none'}} primary={t('projects')} />
                  </ListItemButton>
                </AniLink>
              </ListItem>
              <ListItem disablePadding>
                <ILink 
                  style={{width: '100%', textDecoration: 'none'}}
                  to={`/#services`}>
                  <ListItemButton>
                    <ListItemIcon>
                      <DesignServicesIcon />
                    </ListItemIcon>
                    <ListItemText sx={{color: 'white', textDecoration: 'none'}} primary={t('services')} />
                  </ListItemButton>
                </ILink>
              </ListItem>
              <ListItem disablePadding>
                <ILink 
                  style={{width: '100%', textDecoration: 'none'}}
                  to={`${language === 'en' ? '/en/' : '/'}blog`}>
                  <ListItemButton>
                    <ListItemIcon>
                      <NewspaperIcon />
                    </ListItemIcon>
                    <ListItemText sx={{color: 'white', textDecoration: 'none'}} primary={t('blog')} />
                  </ListItemButton>
                </ILink>
              </ListItem>
            </List>
            <Divider />
            <List>
              <ListItem disablePadding sx={{textAlign: 'center'}}>
                <ILink 
                  style={{width: '90%', textDecoration: 'none', border: '1px solid white',  margin: 'auto'}}
                  to={`/#contact`}>
                  <ListItemButton>
                    <ListItemIcon>
                      <MailIcon />
                    </ListItemIcon>
                    <ListItemText sx={{color: 'white', textDecoration: 'none'}} primary={t('contactUs')} />
                  </ListItemButton>
                </ILink>
              </ListItem>
            </List>
          </Box>
        </Drawer>
        {/* <Box
          component="footer"
          sx={{
            py: 3,
            px: 2,
            mt: 'auto',
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[200]
                : theme.palette.grey[800],
          }}
        >
          <Container maxWidth="sm">
            <Typography variant="body1">
              My sticky footer can be found here.
            </Typography>
            <Copyright />
          </Container>
        </Box> */}
      </Box>
    </ThemeProvider>
  )
}

export default Layout

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    },
  }
`;
